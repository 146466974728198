import React, { useState, useEffect } from 'react'
import { Layout, Form, Button, Input, Space } from 'antd'
import { Rules, Storage, NAV_TAPS_INITIAL, NAV_CACHE_INITIAL, Utils } from '@/utils'
import './Login.less'
import loginBackground from '@/assets/login-background.jpg'
import loginModel from '@/model/Login/loginModel'
import FormItem from 'antd/lib/form/FormItem'
import { withRouter, useHistory } from "react-router-dom"

// redux
import { useDispatch } from 'react-redux'
import { clearNavTaps } from '@/store/actions'

//图片
import beianRS from "@/assets/beianRS.png";

const layout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 18
  }
}

const checkLayout = {
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  }
}
const Login = () => {
  const dispatch = useDispatch();

  const history = useHistory()
  //创建form实例
  const [form] = Form.useForm();
  //校验图片
  const [checkImg, setCheckImg] = useState('')
  // 提交form表单
  const onFinish = (values) => {

    loginModel.userLogin({
      data: {
        userType: 1, // 1后台端，2保险端，3投保企业，4商户/企健付端，5门店, 6持卡人
        nickName: values.username,
        password: Utils.encryptWithSHA256( Utils.encryptData(values.password).toLowerCase()),
        uuid: checkImg.uuid,
        validateCode: values.code
      }
    }

    ).then(({ datas }) => {
      if (datas !== null) {
        form.resetFields()
        getCheckImg()
        Storage.setStorage('userId', datas.userId.toString())    //赋值失败 -- 待解决
        Storage.setStorage("Authorization", `Bearer ${datas.token}`)
        Storage.setStorage('userName', datas.userName)
        Storage.setStorage('userType', datas.userType.toString())
        dispatch(clearNavTaps())
        Storage.setStorage('NAV_TAPS', NAV_TAPS_INITIAL);
        Storage.setStorage('NAV_CACHE', NAV_CACHE_INITIAL);
        setTimeout(() => {
          history.push('/hello')
        })
      }
    }).catch(() => {
      getCheckImg()
    })


  };
  //重置form表单
  const resetForm = () => {
    form.resetFields()
  }
  //获取校验img
  const getCheckImg = () => {
    loginModel.getCheckImg().then(({ datas }) => {
      setCheckImg(datas)
    })
  }
  useEffect(() => {
    getCheckImg()
  }, [])

  const {  Footer } = Layout;
  return (
    <>
      <img alt="" src={loginBackground} style={{ zIndex: "500", position: "absolute", width: "100%" }} />
      <div className="loginBox" >
        <h2 >报表系统</h2>
        <Form {...layout} name="login" labelAlign="left" form={form} onFinish={onFinish} >
          <Form.Item label="账号" name="username" rules={Rules.ruleCommon('账号')}>
            <Input size="large" />
          </Form.Item>
          <Form.Item label="密码" name="password" rules={Rules.ruleCommon('密码')}>
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item label="验证码" >
            <FormItem {...checkLayout} name="code" rules={Rules.ruleCommon('验证码')}>
              <Input size="large" />
            </FormItem>
            <img alt="验证码" className="checkImg" src={checkImg.encodedText} onClick={getCheckImg} />
          </Form.Item>
          <div className="login-btn">
            <Space>
              <Button className="submitButton" htmlType="submit" size="large" >登录</Button>
              <Button type="primary" onClick={resetForm} size="large">重置</Button >
            </Space>
          </div>
        </Form>
      </div>
      <Footer className="footer">
        沪ICP备18034726号-2   
        &nbsp;&nbsp;<a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602003511"
          className="beian"
        >
          <img alt="beianRS" src={beianRS} />
          沪公网安备 31010602003511号
        </a>
      </Footer>
    </>
  )
}

export default withRouter(Login)
