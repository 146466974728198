import React from 'react';
import { message, notification, Modal } from 'antd'; //引入全局提示
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SHA256 from 'js-sha256';
import CryptoJS from 'crypto-js';

import _ from 'lodash'
const { confirm } = Modal;

message.config({
  top: 50,  //消息距离顶部位置
  maxCount: 1, // 最大显示数
  duration: 2
})

notification.config({
  // placement: 'bottomRight',
  // bottom: 50,
  duration: 5,
  // rtl: true,
})

const messageTransform = (paramInfo) => {
  let paramIsString = typeof paramInfo === 'string' || (paramInfo && paramInfo.$$typeof);
  let paramObj = {
    title: '',
    content: '',
    onOk: undefined,
    okButtonProps: {}
  }

  if (paramIsString) {
    paramObj.content = paramInfo
  } else {
    paramObj.title = paramInfo.title || ''
    paramObj.content = paramInfo.content || ''
    paramObj.onOk = paramInfo.onOk || undefined
    paramObj.okButtonProps = paramInfo.okButtonProps || {}
  }
  return paramObj
}

/**
 * 全局提示 - 成功
 * @param {String} text
 */
export const successMsg = (text) => {
  message.success(text)
}
/**
 * 全局提示 - 警告
 * @param {String} text
 */
export const warnMsg = (paramInfo) => {
  let paramObj = messageTransform(paramInfo);
  Modal.warning({
    title: paramObj.title || '警告',
    content: paramObj.content,
    onOk() {
      if (!paramObj.onOk) return false
      paramObj.onOk()
    },
    okButtonProps: paramObj.okButtonProps
  });
}
/**
 * 全局提示 - 失败
 * @param {
 *  title String
 *  content String
 *  onOk method
 * }
 */
export const errorMsg = (paramInfo) => {
  let paramObj = messageTransform(paramInfo);
  Modal.error({
    title: paramObj.title,
    content: paramObj.content,
    onOk() {
      if (!paramObj.onOk) return false
      paramObj.onOk()
    },
    okButtonProps: paramObj.okButtonProps
  });
}
/**
 * 全局提示 - 信息
 * @param {
 *  title String
 *  content String
 *  onOk method
 * }
 */
export const infoMsg = (paramInfo) => {
  let paramObj = messageTransform(paramInfo);
  return Modal.info({
    title: paramObj.title || '信息',
    content: paramObj.content,
    onOk() {
      if (!paramObj.onOk) return false
      paramObj.onOk()
    },
    okButtonProps: paramObj.okButtonProps
  });
}

/**
 * 全局提示 - 交互信息
 * @param {
 *  title 提示标题
 *  content 内容
 *  onOk 确认
 *  onCancel 取消
 * } param0
 */
export const confirmMsg = ({
  title = '',
  content = '',
  onOk = undefined,
  onCancel = undefined
}) => {
  confirm({
    title: title,
    icon: <ExclamationCircleOutlined />, //图标
    content: content,
    onOk() {
      if (!onOk) return false
      onOk()
    },
    onCancel() {
      if (!onCancel) return false
      onCancel()
    },
  });
}


/**
 * 页码改变
 * @param {Function} setFn
 * @param {string} page
 * @param {string} page_size
 */
export const handleChangePageInfo = (page, page_size, setFn) => {
  setFn(prePageInfo => ({
    ...prePageInfo,
    page: page,
    page_size: page_size
  }))
}
//
/**
 * 查询 - resetPage为true 查询时page重置为0 false则不会
 * @param {boolean} resetPage
 * @param {Function} setFn
*/
export const updatePageInfo = (setFn, resetPage = true) => {
  setFn(prePageInfo => ({
    ...prePageInfo,
    page: resetPage ? 1 : prePageInfo.page,
    update_count: ++prePageInfo.update_count
  }))
}

/**
 * 导出excel blob
 * @param  response
 */
export const handleExportExcel = (response, type = "excel") => {
  if (!response) return false
  let mime = ''
  switch (type) {
    case 'excel':
      mime = 'application/vnd.ms-excel;charset=utf-8'
      break
    case 'pdf':
      mime = 'application/pdf'
      break
    default:
  }
  const url = window.URL.createObjectURL(new Blob([response.data], { type: mime }))
  console.log('utils测试 url', url)
  const link = document.createElement('a') // 创建a标签
  link.href = url //设置a标签超链接
  console.log('response', response);
  console.log('response.header', response.header);
  let contentDisposition = response.headers['Content-disposition'] || response.headers['content-disposition'];
  const fileName = decodeURI(splitStr(splitStr(splitStr(contentDisposition, ';')[1], '=')[1], "''")[1] || '') //解码
  link.setAttribute('download', fileName) //添加downLoad属性 规定下载的超链接目标为fileName
  document.body.appendChild(link) //将link标签添加到boyd最后一位
  link.click() //点击link标签
  document.body.removeChild(link) //删除link标签
  window.URL.revokeObjectURL(url) //释放掉blob对象
}
/**
 * 导出excel url
 * @param  res
 */
export const handleExportExcelUrl = (res, type = "excel") => {
  let mime = ''
  switch (type) {
    case 'excel':
      // mime = 'application/vnd.ms-excel;charset=utf-8'
      mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      // mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      break
    case 'pdf':
      mime = 'application/pdf'
      break
    default:
  }
  const url = res.datas
  const link = document.createElement('a') // 创建a标签
  link.href = url //设置a标签超链接
  link.MIME_type = mime
  link.type = mime
  let urlArr = splitStr(splitStr(url, '?')[0], '/')
  const fileName = urlArr[urlArr.length - 1] //解码
  link.setAttribute('download', fileName) //添加downLoad属性 规定下载的超链接目标为fileName
  document.body.appendChild(link) //将link标签添加到boyd最后一位
  link.click() //点击link标签
  document.body.removeChild(link) //删除link标签
  window.URL.revokeObjectURL(url) //释放掉blob对象
}
/**
 * 防抖  非立即执行版
 * @param {Function} fun - 执行的函数
 * @param {Number} delay - 延迟执行的毫秒数
 */
export const debounce = (fn, delay = 300) => {
  let timer = null

  return function(){
    const context = this
    const args = arguments  // 存一下传入的参数
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(function() {
      fn.apply(context, args)
    }, delay)
  }
}

/**
 * 防抖 立即执行版
 * @param {Function} fun  - 执行的函数
 * @param {Number} delay - 延迟执行的毫秒数
 */
export const throttle = (fun, delay = 300) => {
  let canRun = true
  return function () {
    if (!canRun) return
    canRun = false
    setTimeout(() => {
      fun.apply(this, arguments)
      canRun = true
    }, delay)
  }
}

export const transJsonToForm = (params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  })
  return formData
}

// 分隔字符串
const splitStr = (oldStr, separator) => {
  return !oldStr ? '' : _.split(oldStr, separator)
}

// 至少填写一个
/**
 * form表单验证，至少输入一个查询条件!
 * @param {Object} form 对象
 * @param {Array} fieldValueArr 需要校验的字段数组
 */
const ruleLeastOne = (form, fieldValueArr) => {
  if (!fieldValueArr.length) return false
  let valueArr = Object.values(form.getFieldsValue(fieldValueArr))
  let isLeastOne = false // 是否至少输入一个查询条件!
  valueArr.forEach(item => {
    let isString = typeof item === 'string' && item.length > 0
    let isNumber = typeof item === 'number'
    let isObject = typeof item === 'object' && item !== null //datePicker
    if (isString || isNumber || isObject) isLeastOne = true
  })
  if (!isLeastOne) {
    Modal.warning({
      title: '警告',
      content: '至少输入一个查询条件!'
    });
    return false
  }
  return isLeastOne
}

// 获取报错信息
const getErrStr = (error) => {
  let isString = typeof error === 'string';
  if (isString) {
    return error
  } else {
    // 修改当前逻辑需与 request.js `axios.interceptors.response.use` `.catch` 方法同步
    let response = error.response
    if (response) {
      let err = response.data
      if (err && response.status === 401) {
        if (response.code === '40002') {
          return `${err.message || '未知错误'}`
        } else {
          return `${err.message || '未知错误'}, 请重新登录！`
        }
      }
      if (response.status === 504) {
        return '请求超时'
      } else {
        return '未知错误'
      }
    } else {
      if (error && error.toString().indexOf('Error') >= 0) {
        return error.toString()
      } else {
        return '未知错误'
      }
    }
  }
}

const effectEvent = (key, fun) => {
  let reloadKey = `reload_${key}`
  let closeKey = `close_${key}`
  let reloadCount = window.ee.listenerCount(reloadKey)
  let closeCount = window.ee.listenerCount(closeKey)
  if (reloadCount === 0) {
    window.ee.on(reloadKey, function (num) {
      fun()
      // window.ee.removeListener(reloadKey, () => {
      //   console.log('remove event');
      // });
    })
  }
  if (closeCount === 0) {
    window.ee.on(closeKey, function (num) {
      fun()
      window.ee.removeListener(closeKey, () => {
        console.log('remove event');
      });
    })
  }
}

// 解析权限，生成权限数组对象
const transformAuthority = (datas, parentMenu = { id: 0, url: '' }, result = {}) => {
  let useableDatas = _.isArray(datas) && datas.length > 0
  if (useableDatas) {
    datas.forEach(item => {
      switch (item.menuType) {
        case 'M':
          transformAuthority(item.children, { id: item.id, menuUrl: item.menuUrl }, result)
          break;

        case 'C':
          if ('' + parentMenu.id === '' + item.parentId) {
            result[item.menuUrl] = []
            transformAuthority(item.children, { id: item.id, menuUrl: item.menuUrl }, result)
          }
          break;
        case 'F':
          if ('' + parentMenu.id === '' + item.parentId) {
            result[parentMenu.menuUrl].push(item.perms)
          }
          break;
        default:
          break;
      }
    })
    return result
  }
}

// 判断菜单权限
const isAuthorityC = (authorityState = [], C_URL = '') => {
  if (!authorityState) return false
  return authorityState[C_URL] ? true : false
}
// 判断按钮权限
const isAuthorityF = (authorityState = [], C_URL = '', btnAuthorityName = '') => {
  if (!authorityState) return false
  if (!authorityState[C_URL]) return false
  return authorityState[C_URL].includes(Trim(btnAuthorityName))
}

// 去掉前后的空格
const Trim = (str = '') => {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

const str2dom = (strArr) => {
  let str='';
  strArr.forEach((item) => {
    str+= `<div>${item}</div>`
  })
  return <div dangerouslySetInnerHTML={{__html: str}} />;
}

//md5加密
const encryptData = (data) => {
  const encryptedData = CryptoJS.MD5(data).toString();
  return encryptedData;
}

//sha加密
const encryptWithSHA256 = (data) => {
  return SHA256.hex(data);
}

export default {
  handleChangePageInfo,       // 分页改变方法
  updatePageInfo,             // 查询调用方法
  handleExportExcel,          // 导出excel方法
  handleExportExcelUrl,       // 导出excel方法(url)
  debounce,                   // 防抖 - 非立即执行版
  throttle,                   // 防抖 - 立即执行版
  successMsg,                 // 全局提示 - 成功
  errorMsg,                   // 全局提示 - 失败
  warnMsg,                    // 全局提示 - 警告
  infoMsg,                    // 全局提示 - 信息
  confirmMsg,                 // 全局交互
  transJsonToForm,                  // json数据转换成formData
  ruleLeastOne,                // 搜索表单至少输入或选择一个
  splitStr,                     // split 分隔方法
  getErrStr,                   // 获取报错信息的字符串
  effectEvent,                   // effect 监听 清除缓存数据
  transformAuthority,            // 解析权限数组，生成新的数组对象
  isAuthorityF,                     // 判断是否有按钮权限
  isAuthorityC,                      // 判断是否有菜单权限
  str2dom,                           // 字符串转换html换行
  encryptData,//加密
  encryptWithSHA256,//sha加密
}
